export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43')
];

export const server_loads = [0,2];

export const dictionary = {
		"/": [7],
		"/(app)/admin": [8,[2,4],[3]],
		"/(app)/admin/community": [9,[2,4],[3]],
		"/(app)/admin/workspace": [10,[2,4],[3]],
		"/auth": [32],
		"/auth/forgot-password": [33],
		"/auth/lti-add-unit": [34],
		"/auth/signup": [35],
		"/auth/update-password": [36],
		"/(app)/communities": [11,[2],[3]],
		"/(app)/communities/[unit]": [~12,[2],[3]],
		"/(app)/communities/[unit]/details": [14,[2],[3]],
		"/(app)/communities/[unit]/subgroup/[subgroupId]": [15,[2],[3]],
		"/(app)/communities/[unit]/subgroup/[subgroupId]/details": [17,[2],[3]],
		"/(app)/communities/[unit]/subgroup/[subgroupId]/[post]": [16,[2],[3]],
		"/(app)/communities/[unit]/[post]": [13,[2],[3,5]],
		"/dev": [37],
		"/dev/ManageMembers": [38],
		"/dev/gifs": [39],
		"/dev/kit-server": [40],
		"/dev/projects": [41],
		"/logout": [~42],
		"/(app)/my-uni": [~18,[2],[3]],
		"/(app)/my-uni/[unit]": [~19,[2],[3]],
		"/(app)/my-uni/[unit]/subgroup": [~20,[2],[3]],
		"/(app)/my-uni/[unit]/subgroup/[subgroupId]": [~21,[2],[3]],
		"/(app)/network": [22,[2],[3]],
		"/(app)/network/dms": [23,[2,6],[3]],
		"/(app)/network/dms/group/[subgroupId]": [26,[2,6],[3]],
		"/(app)/network/dms/[friendId]": [24,[2,6],[3]],
		"/(app)/network/dms/[friendId]/details": [25,[2,6],[3]],
		"/(app)/notifications": [27,[2],[3]],
		"/(app)/profile/me": [29,[2],[3]],
		"/(app)/profile/me/edit": [30,[2],[3]],
		"/(app)/profile/settings": [31,[2],[3]],
		"/(app)/profile/[profileId]": [28,[2],[3]],
		"/resend": [~43]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';